<script>
  import axios from "axios";

  const BACKSPACE = "⌫";
  const ENTER = "↩";
  const HOST = "";
  const SUBMIT_URL = `${HOST}/submit`;

  let pin = "";
  let status = "";
  let statusColor = "inherit";

  function handleClick(e) {
    const value = e.currentTarget.textContent.trim()[0];

    if (value === BACKSPACE) {
      pin = pin.slice(0, -1);
      return;
    }

    if (value === ENTER) {
      status = "⌛";
      axios
        .get(`${SUBMIT_URL}/?pin=${pin}`)
        .then(() => {
          status = "✅";
          statusColor = "green";

          console.log(status);
        })
        .catch((error) => {
          if (error.response) {
            status = "❌";
            statusColor = "red";

            console.log(error.response);
          }
        });
      return;
    }

    pin += value;
  }
</script>

<link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" />
<div class="container">
  <div id="output">{pin}</div>
  <div class="row">
    <div class="digit" id="one" on:click|preventDefault={handleClick}>1</div>
    <div class="digit" id="two" on:click|preventDefault={handleClick}>
      2
      <div class="sub">ABC</div>
    </div>
    <div class="digit" id="three" on:click|preventDefault={handleClick}>
      3
      <div class="sub">DEF</div>
    </div>
  </div>
  <div class="row">
    <div class="digit" id="four" on:click|preventDefault={handleClick}>
      4
      <div class="sub">GHI</div>
    </div>
    <div class="digit" id="five" on:click|preventDefault={handleClick}>
      5
      <div class="sub">JKL</div>
    </div>
    <div class="digit" on:click|preventDefault={handleClick}>
      6
      <div class="sub">MNO</div>
    </div>
  </div>
  <div class="row">
    <div class="digit" on:click|preventDefault={handleClick}>
      7
      <div class="sub">PQRS</div>
    </div>
    <div class="digit" on:click|preventDefault={handleClick}>
      8
      <div class="sub">TUV</div>
    </div>
    <div class="digit" on:click|preventDefault={handleClick}>
      9
      <div class="sub">WXYZ</div>
    </div>
  </div>
  <div class="row">
    <div class="digit" on:click|preventDefault={handleClick}>{BACKSPACE}</div>
    <div class="digit" on:click|preventDefault={handleClick}>0</div>
    <div class="digit" on:click|preventDefault={handleClick}>{ENTER}</div>
  </div>
</div>

<div
  class="status"
  style="color: {statusColor}; display: {status ? 'flex' : 'none'}"
>
  {status}
</div>

<style>
  .status {
    width: 100%;
    position: absolute;
    top: 0;
    background-color: white;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10vh;
    text-align: center;
    font-weight: lighter;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }

  .row {
    margin: 0 auto;
    width: 100%;
    clear: both;
    text-align: center;
    /* font-family: "Roboto"; */
    display: flex;
    height: 20vh;
  }

  .digit {
    float: left;
    width: 33%;
    font-size: 10vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: flex-start;
  }

  .sub {
    font-size: 2vh;
    color: grey;
  }

  .container {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    padding: 0;
    margin: 0;
    height: 100%;
    text-align: center;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  #output {
    display: flex;
    /* font-family: "Exo"; */
    font-size: 14vmin;
    height: 20vh;
    font-weight: bold;
    color: #1976d2;
    justify-content: center;
    align-items: center;
  }

  .digit:active {
    background-color: #e6e6e6;
  }
</style>
